<template>
  <b-card header="Edit Topic">
    <topic-form
      v-if="topicLoaded"
      :submit="editTopic"
      :topic="topic"
    />

    <upload-progress
      class="mt-1"
      :progress="progress"
    />
  </b-card>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TopicForm from '@/common/components/TopicsCalendar/TopicForm.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'

export default {
  name: 'EditTopic',
  components: { TopicForm, UploadProgress },
  data() {
    return {
      topic: {},
      topicLoaded: false,
      previewImage: '',
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { progress, calculateUploadPercentage } = uploadPercentage()
    return {
      progress, calculateUploadPercentage, successfulOperationAlert,
    }
  },
  created() {
    this.getTopic()
  },
  methods: {
    getTopic() {
      this.$activities.get(`/internalops/weekly/calendar/topics/${this.$route.params.id}`).then(res => {
        const topic = res.data.data
        this.topic = {
          ...topic,
          session: topic.live_id,
          activityName: topic.live,
          entityId: topic.entity_id,
          image: [topic.image],
        }

        this.topicLoaded = true
      })
    },
    editTopic() {
      return this.$activities.put(`/internalops/weekly/calendar/topics/${this.$route.params.id}`,
        { ...this.topic, image: this.topic.image[0] },
        {
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        }).then(() => {
        this.successfulOperationAlert('Topic is edited successfully')
        this.$router.push({ name: 'topics-calendar-list' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
